.Cerification_Row {
	height: 100%;
	display: block;
	border-style: solid;
	border-color: black;
	border-width: 0px;
	border-bottom-width: 4px;
}
.Bottom_Row {
	border-bottom: 0px;
}
.Row_Title {
	font-size: 300%;
	text-align: center;
}
.Certifications {
	position: relative;
	top: 10%;
	width: 100%;
	height: 70%;

	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.Certification {
	text-align: center;
	width: 20vw;
	font-size: 220%;
	cursor: pointer;
	text-decoration: none;
	color: black;
}
@media screen and (max-width: 1000px) {
	.Certification {
		width: 30vw;
	}
}
.Certification_Image {
	height: 20vh;
}
@media screen and (max-width: 1000px) {
	.Certification_Image {
		width: 30vw;
		height: auto;
	}
}
#CertificationBlock {
	display: flex;
	height: 100vh;
	flex-direction: column;
	background: #f8fbef;
	background: linear-gradient(155deg, #8895b3 0%, #2d3c62d2 100%);
}
